import {
  ItemProps,
  ItemVariant,
  ItemVariantMM2,
} from '@/shared/ui/item/item.types';

import { ItemImageByVariant } from './item-image-by-variant/item-image-by-variant';
export const Item = ({
  className,
  variant,
  size,
  ...props
}: ItemProps<ItemVariant | ItemVariantMM2>) => {
  return (
    <ItemImageByVariant<ItemVariant | ItemVariantMM2>
      {...props}
      className={className}
      variant={variant}
      size={size}
    />
  );
};
