import Image from 'next/image';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { Game } from '@/shared/types/common';

interface ItemProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  children?: ReactNode;
  size?: number;
  variant: Game;
  style?: React.CSSProperties;
}

export const IllustrationGame = ({
  size = 32,
  variant,
  className,
  style,
}: ItemProps) => {
  const imageLoader = ({ src }: { src: string }) => {
    return `/assets/drop-list-game/${src}`;
  };

  return (
    <Image
      style={style}
      className={className}
      loader={imageLoader}
      src={`${variant}.webp`}
      width={size}
      height={size}
      quality={100}
      loading="lazy"
      alt={`${variant} item`}
    />
  );
};
